<template>
  <div class="error" v-if="error && error.summary">
    <Alert :alert="error"></Alert>
  </div>

  <div class="list-edit-container" v-else>
    <div class="thumbnail-column">
      <Thumbnail
        :key="list.thumbnailUrl"
        :url="list.thumbnailUrl"
        class="thumbnail"
        :title="'Thumbnail for ' + list.title"
        size="standard"
        :show-placeholder="true"
        :blur="false"></Thumbnail>
    </div>

    <div class="details-column">
      <div class="form">
        <ValidationObserver ref="observer" tag="div" key="editListDetails">
          <ValidationProvider rules="required" v-slot="{ errors, classes }" name="Title">
            <label for="title" class="sr-only">Title:</label>
            <b-form-input id="title" v-model="list.title" :class="classes" size="lg"></b-form-input>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>

          <template v-if="listOwner">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Visibility"
              :customMessages="visibilityError"
              class="visibility">
              <label for="listType">List type:</label>
              <b-form-radio-group
                id="listType"
                v-model="list.priv"
                :required="true"
                class="d-inline-block"
                @change="visibilityChanged"
                name="listVisibility">
                <b-form-radio :value="false">Public</b-form-radio>
                <b-form-radio :value="true" :disabled="!authenticated"
                  >Private
                  <a href="javascript:void(0)" @click="login" v-if="!authenticated"
                    >(login required)</a
                  ></b-form-radio
                >
              </b-form-radio-group>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>

            <div class="collaboration">
              <b-form-checkbox
                v-model="list.collaborative"
                :disabled="list.priv || !myUserProfile.publiclyVisible"
                switch
                >Collaborative
              </b-form-checkbox>
              <span v-if="list.priv" class="statement"
                >Only public lists can be made collaborative</span
              >
            </div>

            <Alert
              :alert="{ summary: 'Warning: ' + warning, impact: 'warning' }"
              v-if="warning"></Alert>
          </template>
          <div>
            <label for="listdescription">Description:</label>
          </div>
          <b-form-textarea id="listdescription" v-model="list.description"></b-form-textarea>
        </ValidationObserver>

        <div class="buttons">
          <b-button variant="success" @click="save" :disabled="inProgress || saving">Save</b-button>
          <b-button variant="link" @click="cancel">Cancel</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-edit-container {
  display: flex;

  .thumbnail-column {
    position: relative;
  }

  .details-column {
    flex: 1;
    margin-left: 1rem;

    .form {
      width: 100%;
      margin-bottom: 1rem;

      label,
      .label {
        font-weight: bold;
        margin-right: 1rem;
        max-width: 100px;
        width: 100px;
        display: inline-block;
      }

      textarea {
        height: 19.5em;
      }

      .invalid {
        border: solid 1px colour(form-field-error);
      }

      .error {
        color: colour(form-field-error);
      }

      .hint {
        font-size: 0.8rem;
        color: colour(grey-1);
      }

      .visibility,
      .collaboration {
        display: inline-block;
        margin-right: 1rem;

        .custom-control {
          display: inline-block;
        }

        .custom-control-input:disabled ~ .custom-control-label {
          color: colour(grey) !important;
        }

        .statement {
          margin-left: 1rem;
        }
      }

      .facebook {
        label {
          display: inline-block;
        }

        .input-prefix {
          display: inline-block;
          margin-right: 0.5rem;
        }

        input {
          display: inline-block;
          width: 400px;
        }
      }

      .buttons {
        margin-top: 1rem;
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import auth from '@/mixins/auth'
import Alert from '@/components/Alert.vue'
import Thumbnail from '@/components/Thumbnail.vue'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'The "{_field_}" field is required',
})

export default {
  name: 'EditListDetails',
  mixins: [auth],
  components: {
    Thumbnail,
    ValidationProvider,
    ValidationObserver,
    Alert,
  },
  props: {
    existing: {
      type: Object,
      required: true,
    },
    inProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      error: undefined,
      wasCollaborative: false,
      wasPublic: false,
      list: {},
      saving: false,
    }
  },
  created() {
    let self = this
    self.reset()
  },
  computed: {
    visibilityError() {
      return { required: 'You must specify the visibility of this tag' }
    },
    warning() {
      let warning = undefined
      if (this.wasCollaborative && !this.list.collaborative) {
        if (this.list.priv) {
          warning =
            'Changing a collaborative list to a private list will immediately remove all existing collaborators ' +
            'and their contributions to this list, and will delete all tags and notes associated with the list ' +
            'by other people. Your own contributions will be unaffected.'
        } else {
          warning =
            'Changing a collaborative list to a non-collaborative public list will immediately remove all existing ' +
            'collaborators and their contributions to this list. Your own contributions will be unaffected.'
        }
      } else if (this.wasPublic && this.list.priv) {
        warning =
          'Changing a public list to a private list means that any tags and notes associated with the list by other ' +
          'people will be deleted. Your own contributions will be unaffected.'
      }
      return warning
    },
    listOwner() {
      return (
        this.list.author === this.simpleUsername ||
        this.list.author === this.username ||
        this.list.author === this.fullUsername
      )
    },
    isListCollaborator() {
      let self = this
      return (
        self.list.collaborators &&
        _.findIndex(self.list.collaborators, (c) => c.user.fullUserId === self.fullUsername) > -1
      )
    },
    isListAdministrator() {
      let self = this
      return (
        self.list.collaborators &&
        _.findIndex(
          self.list.collaborators,
          (c) => c.user.fullUserId === self.fullUsername && c.role === 'ADMINISTRATOR'
        ) > -1
      )
    },
  },
  methods: {
    save() {
      let self = this
      this.error = undefined

      self.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          if (this.warning) {
            self.$bvModal
              .msgBoxConfirm(
                this.warning +
                  ' This operation cannot be undone. Are you sure you wish to continue?'
              )
              .then((result) => {
                if (result) {
                  self.$emit('save', self.list)
                }
              })
          } else {
            self.$emit('save', self.list)
          }
        }
      })
    },
    cancel() {
      let self = this
      self.saving = false
      self.reset()
      self.$emit('cancel')
    },
    reset() {
      let self = this
      _.forOwn(self.existing, function (val, key) {
        self.$set(self.list, key, val)
      })
      this.wasCollaborative = this.list.collaborative
      this.wasPublic = !this.list.priv
      this.saving = false
      this.error = undefined
      this.list.description = _.replace(this.list.description, /<br\/>/g, '\n')
    },
    visibilityChanged() {
      let self = this
      this.$nextTick(() => {
        if (self.list.priv) {
          self.list.collaborative = false
          self.list.facebookGroupId = ''
        }
      })
    },
  },
}
</script>
