import { render, staticRenderFns } from "./CopyrightStatus.vue?vue&type=template&id=7c45c2c7&scoped=true&"
import script from "./CopyrightStatus.vue?vue&type=script&lang=js&"
export * from "./CopyrightStatus.vue?vue&type=script&lang=js&"
import style0 from "./CopyrightStatus.vue?vue&type=style&index=0&id=7c45c2c7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c45c2c7",
  null
  
)

export default component.exports