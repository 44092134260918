<template>
  <Stubbable :stubbed="stubbed">
    <template v-slot:content>
      <div class="list-summary-container" :class="{ compact: compact }">
        <div class="list-container d-flex">
          <div class="thumbnail-column">
            <PossibleLink :route="headerRoute">
              <Thumbnail
                :key="list.thumbnailUrl"
                :url="list.thumbnailUrl"
                class="thumbnail"
                :title="'Thumbnail for ' + title"
                :size="compact ? 'small' : 'standard'"
                :show-placeholder="true"
                :blur="blurThumbnail"
                @load-succeeded="thumbnailLoaded"
                @load-failed="thumbnailFailedToLoad"></Thumbnail>
            </PossibleLink>
          </div>
          <div class="body">
            <h1>
              <PossibleLink :route="headerRoute">{{ title }}</PossibleLink>
            </h1>

            <div class="details" v-if="!titleAndThumbnailOnly">
              <div>
                <span class="format"
                  ><font-awesome-icon :icon="formatIcon" />{{ listVisibility }}
                  List
                </span>
                <span v-if="list.numItems">- {{ numItems }}</span>
                <span class="author" v-if="!isMyList || (isMyList && !hideOwnerOnMyLists)">
                  <UserThumbnail :user-summary="list.owner" size="small"></UserThumbnail>
                </span>
                <span class="date"
                  ><font-awesome-icon :icon="{ prefix: 'far', iconName: 'calendar' }" />{{
                    createdDate
                  }}</span
                >
              </div>
              <div>
                <span v-if="showCollaboratorCount && list.collaborative" class="collaborators"
                  ><font-awesome-icon
                    :icon="{ prefix: 'fas', iconName: 'users' }"
                    class="author-icon" />
                  {{ list.collaboratorCount }} Voluntrove{{
                    list.collaboratorCount !== 1 ? 's' : ''
                  }}
                  collaborating on this list</span
                >
              </div>

              <ListDescription
                :list="list"
                v-if="showSummary"
                :show-heading="!compact"
                :truncate="compact"></ListDescription>
            </div>

            <slot name="extra-content"></slot>
          </div>
          <div class="float-right"><slot name="float-right"></slot></div>
        </div>
      </div>
    </template>
    <template v-slot:stub>
      <div class="d-flex stub">
        <div class="thumbnail"></div>
        <div class="body">
          <div class="line" style="width: 30%"></div>
          <div class="line" style="width: 60%"></div>
          <div class="block"></div>
          <div class="line" style="width: 20%"></div>
        </div>
      </div>
    </template>
  </Stubbable>
</template>

<script>
import _ from 'lodash'
import utils from '@/mixins/utils'
import colours from '@/mixins/colours'
import cultural from '@/mixins/cultural'
import moment from 'moment'
import auth from '@/mixins/auth'
import lists from '@/mixins/lists'
import mappings from '@/mixins/mappings'
import Stubbable from '@/components/Stubbable.vue'
import UserThumbnail from '@/components/profile/UserThumbnail'
import Thumbnail from '@/components/Thumbnail.vue'
import PossibleLink from '../common/PossibleLink'
import ListDescription from '@/components/lists/ListDescription.vue'

export default {
  name: 'ListSummary',
  mixins: [mappings, utils, colours, auth, lists, cultural],
  components: {
    ListDescription,
    PossibleLink,
    Stubbable,
    UserThumbnail,
    Thumbnail,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
    headerRoute: {
      type: Object,
      required: false,
    },
    bordered: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
    hideOwnerOnMyLists: {
      type: Boolean,
      required: false,
    },
    showCollaboratorCount: {
      type: Boolean,
      required: false,
    },
    showSummary: {
      type: Boolean,
      required: false,
      default: true,
    },
    titleAndThumbnailOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      thumbnailUrl: '',
      availableThumbs: [],
    }
  },
  computed: {
    stubbed() {
      return this.list === undefined
    },
    listVisibility() {
      return this.list.collaborative ? 'Collaborative ' : this.list.priv ? 'Private ' : 'Public '
    },
    yearRange() {
      return this.list.yearRange
    },
    title() {
      return this.list.title ? this.list.title : this.list.name ? this.list.name : ''
    },
    numItems() {
      return this.list.numItems === 0 || this.list.numItems > 1
        ? this.list.numItems + ' items'
        : this.list.numItems + ' item'
    },
    blurThumbnail() {
      return this.list.thumbnailIsCulturallySensitive && this.suppressContent(this.list.id)
    },
    createdDate() {
      return this.formattedDate(
        moment(this.list.createdDate || this.list.created).format('DD MMM YYYY')
      )
    },
    shortDescription() {
      // Strip HTML from the description so we don't have to work around the formatting
      // when shortening the description.
      let tpl = document.createElement('div')
      tpl.innerHTML = this.list.description.trim()
      return tpl.innerText
    },
    linkDescriptor() {
      return {
        name: 'List',
        params: {
          id: this.list.id,
        },
      }
    },
    format() {
      return this.mapFormat(this.list.formatDisplay)
    },
    formatIcon() {
      return this.mapFormatIcon(this.format, this.defaultFormatIcon)
    },
    defaultFormatIcon() {
      return { prefix: 'fas', iconName: 'list' }
    },
    isMyList() {
      return (
        _.toLower(this.list.author) === this.simpleUsername ||
        _.toLower(this.list.author) === this.username ||
        _.toLower(this.list.author) === this.fullUsername
      )
    },
  },
  methods: {
    thumbnailLoaded(dimenions) {
      this.$emit('thumbnail-loaded', dimenions)
    },
    thumbnailFailedToLoad() {
      this.$emit('thumbnail-failed')
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../scss/list-view.scss';

.list-summary-container {
  margin-bottom: 1em;

  h1 {
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
  }

  @supports (font-variation-settings: normal) {
    h1 {
      font-family: 'Source Serif Variable', serif;
    }
  }

  &.bordered {
    .list-container {
      background-color: #ffffff;
      border: 1px solid colour('grey-5');
      padding: 1em;
    }
  }

  .facebook {
    font-size: 1.5rem;
  }

  &.compact {
    h1 {
      font-family: 'Source Serif Pro', serif;
      font-weight: 700;
      font-size: 1.8rem;
    }

    @supports (font-variation-settings: normal) {
      h1 {
        font-family: 'Source Serif Variable', serif;
      }
    }

    .list-type {
      color: colour(grey-2);
      padding-right: 1.5em;
      font-weight: 400;

      svg {
        margin-right: 0.5em;
      }
    }

    .thumbnail-column {
      .thumbnail {
        margin-right: 1em;
      }
    }
  }

  .list-container {
    &:last-child {
      margin-bottom: 0;
    }

    .owner {
      color: colour(blue);
      padding-right: 1.5em;

      svg {
        margin-right: 0.5em;
      }
    }

    .body {
      flex: 1;
    }

    .float-right {
      display: inline-block;
      float: right;
      margin-top: 5px;
      margin-left: 1rem;
    }

    .collaborators {
      margin-right: 1rem;
    }
  }
}

.trending-list-container {
  .date {
    margin-left: 0.5em;

    svg {
      margin-right: 0.25em;
    }
  }
}
</style>
